.select-network {
    background: linear-gradient(73.6deg, #85FFC4 2.11%, #5CC6FF 42.39%, #BC85FF 85.72%);
    border: none;
    height: 40px;

}
.select-network-drop {
   
    position: absolute;
 
    width: 162px;
    height: fit-content;
    z-index: 99;
    padding-top: 10px;
    

}